import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import Portfolio from '../components/Portfolio'

class HomeIndex extends React.Component {
  render() {
    const {
      frontmatter: { title: aboutTitle, path: aboutPathRaw },
      excerpt: aboutLead,
    } = this.props.data.about
    const aboutPath = String(aboutPathRaw).replace(/^\s*(.*?)\/?\s*$/, '$1')

    return (
      <Layout location={this.props.location}>
        <section id="one">
          <header className="major">
            <h2>{aboutTitle}</h2>
          </header>
          <p>{aboutLead}</p>
          <ul className="actions">
            <li>
              <Link to={aboutPath} className="button">
                Více
              </Link>
            </li>
          </ul>
        </section>

        {this.props.data.portfolio.edges.length > 0 && (
          <section id="two">
            <header className="major">
              <h2>Moje malování</h2>
            </header>

            <Portfolio
              basepath="/portfolio"
              posts={this.props.data.portfolio.edges.map(({ node }) => node)}
            />

            <ul className="actions">
              <li>
                <Link to="/portfolio/" className="button">
                  Celé portfolio malování
                </Link>
              </li>
            </ul>
          </section>
        )}

        {this.props.data.baking.edges.length > 0 && (
          <section id="three">
            <header className="major">
              <h2>Moje pečení</h2>
            </header>

            <Portfolio
              basepath="/baking"
              posts={this.props.data.baking.edges.map(({ node }) => node)}
            />

            <ul className="actions">
              <li>
                <Link to="/baking/" className="button">
                  Celé portfolio pečení
                </Link>
              </li>
            </ul>
          </section>
        )}
      </Layout>
    )
  }
}

export default HomeIndex

export const pageQuery = graphql`
  query HomeIndexQuery {
    portfolio: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//portfolio//" }
        frontmatter: { published: { eq: true } }
      }
      limit: 4
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...PortfolioPost
        }
      }
    }

    baking: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "//baking//" }
        frontmatter: { published: { eq: true } }
      }
      limit: 4
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...PortfolioPost
        }
      }
    }

    about: markdownRemark(fileAbsolutePath: { regex: "//pages/about//" }) {
      id
      html
      excerpt(pruneLength: 300)
      frontmatter {
        title
        path
      }
    }
  }
`
